<template>
  <header
    id="header"
    class="relative top-0 z-40 flex h-[72px] w-full flex-row items-center justify-between bg-bg-dark px-3 text-white sm:fixed lg:px-10"
  >
    <NuxtLink class="relative z-10" to="/">
      <ClientOnly>
        <Logo class="hidden lg:block" />
        <img class="w-8 lg:hidden" src="/assets/images/small-logo.png" alt="logo" />
      </ClientOnly>
    </NuxtLink>

    <nav
      v-if="authStore.getIsLoggedIn"
      class="absolute inset-x-0 mx-auto hidden flex-row items-center justify-center gap-9 md:flex"
    >
      <NuxtLink
        v-for="item in allNavItems"
        :key="item.icon"
        :to="item.path"
        class="flex flex-row gap-4 no-underline transition-all duration-500"
        :class="{
          'rounded-xl bg-white/10 px-6 py-3': item.routesIncluded.find((r) => route.name?.toString() === r),
        }"
      >
        <span class="relative">
          <UiIcon :name="item.icon"></UiIcon>
        </span>

        <h5 v-if="item.routesIncluded.find((r) => route.name?.toString() === r)">{{ item.name }}</h5>
      </NuxtLink>
    </nav>
    <div v-if="authStore.getIsLoggedIn" class="z-10 flex flex-row items-center gap-5">
      <HeaderUserMenu />
    </div>
  </header>
</template>

<script setup lang="ts">
import { useAuthStore } from '~/store/auth'
import Logo from '@/assets/images/logo.svg?component'

const authStore = useAuthStore()

const route = useRoute()
const allNavItems = computed(() => [
  {
    icon: 'home',
    name: 'Property',
    path: '/properties/all',
    routesIncluded: ['properties', 'properties-all', 'properties-exclusives', 'property-id'],
  },
  {
    icon: 'settings',
    name: 'Admin settings',
    path: '/admin-settings/users',
    routesIncluded: ['admin-settings', 'admin-settings-users'],
  },
])
</script>
