import { setUser } from '@sentry/vue'
import { TOKEN_KEY } from '~/constants'
import { useAuthStore } from '~/store/auth'

export const useUser = () => {
  const authStore = useAuthStore()
  return authStore.getIsLoggedIn ? authStore.getUser : null
}

export const useGoogleLogin = () => {
  const config = useRuntimeConfig()

  return navigateTo(`${config.public.API_URL}auth/oauth/login?redirect=${config.public.APP_URL}`, {
    external: true,
  })
}

export const useLogout = async () => {
  const { $api } = useNuxtApp()
  const authStore = useAuthStore()
  const token = useStatefulCookie(TOKEN_KEY)

  return await $api<{}>(`auth/logout`, {
    method: 'DELETE',
  }).finally(() => {
    token.value = null

    setUser(null)

    authStore.clearUser()

    navigateTo('/')
  })
}
