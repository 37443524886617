<template>
  <Teleport to="body">
    <TransitionGroup name="fade">
      <Component
        :is="popup.component"
        v-for="popup in uiStore.getDynamicPopups"
        :key="popup.id"
        :model-value="popup.value"
        v-bind="popup.data"
        v-on="popup.events"
        @update:model-value="uiStore.cleanPopup(popup.id)"
      />
    </TransitionGroup>
  </Teleport>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()
</script>

<style scoped></style>
