import getUniqueNumber from './utils/uniqueId'

export const PERMISSIONS = {}

export const ENVS = {
  DEV: 'dev',
  STAGING: 'STAGING',
  PROD: 'prod',
}

export const DEFAULT_DEBOUNCE_INPUT_TIME = 500

export enum DICTIONARIES {
  SALE_STATUS = 'sale_status',
  PROPERTY_TYPE = 'property_type',
  DEVELOPER_NAMES = 'developer_names',
  LOCATION = 'location',
  PROPERTY_AMENITY = 'property_amenity',
  EXCLUSIVE_UNIT_BEDROOM_TYPES = 'exclusive_unit_bedroom_types',
  EXCLUSIVE_UNIT_STATUSES = 'exclusive_unit_statuses',
  EXCLUSIVE_UNIT_TYPES = 'exclusive_unit_types',
  PROPERTIES = 'properties',
}

export const PROPERTIES_SALE_STATUSES = {
  ANNOUNCEMENT: 'announcement',
  LAUNCH: 'launch',
  ON_SALE: 'on_sale',
  PENDING: 'pending',
  PRE_SALE: 'presale(_e_o_i)',
  SOLD_OUT: 'sold_out',
}

export const PROPERTIES_ROOMS_MAP: { [key: string]: string } = {
  rooms_studio: 'Studio',
  rooms_1: '1 BR',
  rooms_2: '2 BR',
  rooms_3: '3 BR',
  rooms_4: '4 BR',
  rooms_5: '5 BR',
  rooms_6: '6 BR',
}

export enum USER_STATUS_CODES {
  INVITED = 'invited',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const TOKEN_KEY = 'token'

export const EXCLUSIVE_PLACEHOLDER_ID = getUniqueNumber()
