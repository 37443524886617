import { stringify } from 'qs'
import { captureMessage } from '@sentry/vue'
import { useUiStore } from '~/store/ui'
import { TOKEN_KEY } from '~/constants'

/* eslint-disable no-console */
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const uiStore = useUiStore()
  const token = useStatefulCookie(TOKEN_KEY)

  const api = $fetch.create({
    baseURL: runtimeConfig.public.API_URL,
    retry: 0,
    onRequest({ options }) {
      if (token.value) {
        options.headers.set('Authorization', `Bearer ${token.value}`)
      }

      if (options.params) {
        const convertedOptions = new URLSearchParams(stringify(options.params))
        options.params = Object.fromEntries(convertedOptions)
      }
    },
    onResponseError({ response }) {
      switch (response.status) {
        case 401:
          break
        case 422:
          // Don't show snack bar with validation errors
          break
        case 404:
          throw createError({
            message: response._data.message,
            data: response._data,
            statusCode: response.status,
          })
        case 400:
          throw createError({
            message: response._data.message,
            data: response._data,
            statusCode: response.status,
          })
        case 500:
          throw createError({
            message: response._data.message,
            data: response._data,
            statusCode: response.status,
          })
        default:
          uiStore.showSnackBanner('An unexpected error occurred. Please try again later.', 'error')
      }

      captureMessage(response?._data?.message, 'error')

      throw createError({
        message: response._data.message,
        data: response._data,
        statusCode: response.status,
      })
    },
  })

  return {
    provide: {
      api,
    },
  }
})
