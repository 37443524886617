import { defineStore } from 'pinia'
import type {
  DynamicActionsMenu,
  DynamicComponent,
  SnackBanner,
  SnackTypes,
  TableAction,
  TablesEnum,
  Toast,
  UsersFilters,
} from '@/types'
import type {
  PropertiesExclusiveFilters,
  PropertiesFilters,
  Property,
  PropertyDynamicExclusivesUnits,
  PropertyExclusiveFilters,
} from '~/types/properties'

type BaseTableFilters = {
  users: UsersFilters | undefined
  properties: PropertiesFilters | undefined
  propertiesExclusivesUnits: PropertiesExclusiveFilters | undefined
}

type DynamicTableFilters = {
  [key in PropertyDynamicExclusivesUnits]?: PropertyExclusiveFilters
}

type UiStore = {
  tableFilters: BaseTableFilters & DynamicTableFilters
  snackBanner: SnackBanner
  dynamicPopups: DynamicComponent[]
  dynamicSidePanel: DynamicComponent
  dynamicMobilePanel: DynamicComponent
  dynamicActionBar: DynamicComponent
  dynamicActionsMenu: DynamicActionsMenu
  tableItemsPerPage: number
  toasts: Toast[]
}

const initDynamicComponent = () => ({
  value: false,
  component: undefined,
  data: {},
  events: {},
})

const initActionsMenu = () => ({
  value: false,
  actions: [],
})

export const useUiStore = defineStore('ui', {
  state: (): UiStore => ({
    tableFilters: {
      users: undefined,
      properties: undefined,
      propertiesExclusivesUnits: undefined,
    },
    snackBanner: {
      value: false,
      type: 'success',
      text: '',
    },
    dynamicPopups: [],
    dynamicSidePanel: initDynamicComponent(),
    dynamicMobilePanel: initDynamicComponent(),
    dynamicActionBar: initDynamicComponent(),
    dynamicActionsMenu: initActionsMenu(),
    tableItemsPerPage: 20,
    toasts: [],
  }),
  actions: {
    showSnackBanner(
      text: string = '',
      type: SnackTypes = 'success',
      action?: Function,
      actionButtonText?: string,
      secondaryAction?: Function,
      secondaryActionButtonText?: string
    ) {
      this.snackBanner = {
        value: true,
        type,
        text: text || (type === 'success' ? 'Success' : 'Something went wrong'),
        action,
        actionButtonText,
        secondaryAction,
        secondaryActionButtonText,
      }
    },
    showPopup(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicPopups.push({
        id: crypto.randomUUID(),
        value: true,
        component: markRaw(component),
        data,
        events,
      })
    },
    cleanPopup(id: DynamicComponent['id']) {
      this.dynamicPopups = this.dynamicPopups.filter((popup) => popup.id !== id)
    },
    showSidePanel(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicSidePanel = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanSidePanel() {
      this.dynamicSidePanel = initDynamicComponent()
    },
    showMobilePanel(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicMobilePanel = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanMobilePanel() {
      this.dynamicMobilePanel = initDynamicComponent()
    },
    showActionBar(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicActionBar = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanActionBar() {
      this.dynamicActionBar = initDynamicComponent()
    },
    resetSnackBanner() {
      this.snackBanner = {
        value: false,
        type: 'success',
        text: '',
      }
    },
    setTableFilters(table: TablesEnum, filters: any) {
      // @ts-ignore TODO: resolve it
      this.tableFilters[table as keyof typeof this.tableFilters] = filters
    },
    showDynamicActionsMenu(name: string, actions: TableAction[], x: number, y: number) {
      if (this.dynamicActionsMenu?.value) {
        this.cleanDynamicActionsMenu()
        setTimeout(() => {
          this.dynamicActionsMenu = {
            value: true,
            actions,
            name,
            x,
            y,
          }
        })
      } else {
        this.dynamicActionsMenu = {
          value: true,
          actions,
          name,
          x,
          y,
        }
      }
    },
    cleanDynamicActionsMenu() {
      this.dynamicActionsMenu = initActionsMenu()
    },
    setTableItemsPerPage(value: number) {
      this.tableItemsPerPage = value
    },
    showToast(type: string, props: Record<string, any>) {
      const id = Date.now()
      this.toasts.unshift({ id, type, props })

      setTimeout(() => {
        this.removeToast(id)
      }, 5000)
    },
    removeToast(id: number) {
      this.toasts = this.toasts.filter((toast: Toast) => toast.id !== id)
    },
  },
  getters: {
    getSnackBanner: (state) => state.snackBanner,
    getDynamicPopups: (state) => state.dynamicPopups,
    getDynamicSidePanel: (state) => state.dynamicSidePanel,
    getDynamicMobilePanel: (state) => state.dynamicMobilePanel,
    getDynamicActionBar: (state) => state.dynamicActionBar,
    getDynamicActionsMenu: (state) => state.dynamicActionsMenu,
    getTableItemsPerPage: (state) => state.tableItemsPerPage,
    getToasts: (state) => state.toasts,
    getUsersFilters: (state) => state.tableFilters.users,
    getPropertiesFilters: (state) => state.tableFilters.properties,
    getPropertiesExclusiveFilters: (state) => state.tableFilters.propertiesExclusivesUnits,
    getPropertyExclusivesUnits: (state) => {
      return (id: Property['id']) => state.tableFilters[`propertyExclusivesUnits-${id}`]
    },
  },
  persist: {
    storage: localStorage,
    pick: ['tableFilters', 'tableItemsPerPage'],
  },
})
