import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "168",
  height: "21",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F5DBAD",
      d: "M15.71 2.212V.791H.316v1.421h6.857v17.8h1.68v-17.8zM32.674.791h-1.68v9.054H20.044V.79h-1.68v19.22h1.68v-8.69h10.948v8.69h1.681zM49.098 14.886c-.19-2.005-.976-3.76-2.575-4.429 1.952-.501 3.523-2.033 3.523-4.568 0-3.065-1.842-4.624-4.607-4.986C44.572.791 43.84.791 42.89.791h-5.5v19.22h1.68v-8.69h3.82c.434 0 .976 0 1.465.028 2.005.111 2.818 1.7 3.061 3.733.272 2.507.597 3.704 1.058 4.93h1.87c-.651-1.086-.976-2.48-1.247-5.126M39.071 9.872V2.24h3.82c.895 0 1.491.028 2.142.112 2.032.305 3.197 1.42 3.197 3.676s-1.354 3.621-3.415 3.788c-.623.056-1.083.056-1.896.056zM55.358 20.012V.79h-1.68v19.22zM67.2 20.012 74.139.79h-1.816l-6.206 17.884L60.02.79h-1.816l6.748 19.22zM88.31 19.093H77.495v-8.245h9.919v-.892h-9.92V1.683h10.543V.79H76.467v19.22h11.842zM97.062.4c-3.197 0-5.907 2.034-5.907 4.959 0 3.008 1.896 4.234 5.745 5.292 3.658 1.03 5.176 2.09 5.176 4.735 0 2.452-2.168 4.095-5.014 4.095-2.872 0-5.284-1.615-5.69-4.596h-1.085c.407 3.705 3.252 5.515 6.748 5.515 3.523 0 6.152-2.2 6.152-5.18 0-3.065-1.924-4.486-5.854-5.572-3.523-1.003-5.068-1.866-5.068-4.456s2.44-3.873 4.716-3.873c2.574 0 4.444 1.644 4.851 4.485h1.084C102.564 2.824 100.639.4 97.062.4M120.476 1.683V.79h-15.095v.892h7.019v18.329h1.03V1.682zM133.078 12.686l2.683 7.326h1.111L129.826.79h-1.355l-7.127 19.22h1.111l2.629-7.325zm-7.643-.92 3.686-10.11 3.632 10.11zM153.267 1.683V.79h-15.095v.892h7.019v18.329h1.03V1.682zM167.684 19.093h-10.813v-8.245h9.919v-.892h-9.919V1.683h10.542V.79h-11.571v19.22h11.842z"
    }, null, -1)
  ])))
}
export default { render: render }