import { defineStore } from 'pinia'
import type { User } from '@/types'

type AuthState = {
  user: Partial<User>
  userImage: string
  authError: string
  permissions: string[] // only codes
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: {
      id: null,
      email: '',
      name: '',
    },
    userImage: '',
    permissions: [],
    authError: '',
  }),
  actions: {
    setUser(user: User) {
      this.user = user
    },
    clearUser() {
      this.$reset()
    },
    setAuthError(error: string) {
      this.authError = error
    },
  },
  getters: {
    getUser: (state) => state.user,
    getRoleCode: (state) => state.user?.role?.code,
    getPermissions: (state) => state.permissions,
    getIsLoggedIn: (state) => Boolean(state.user?.id),
    getUserImage: (state) => state.userImage,
  },
  persist: {
    pick: ['token', 'user', 'permissions'],
  },
})
