<template>
  <div ref="avatar" class="relative flex flex-row items-center gap-4">
    <div class="relative cursor-pointer select-none" @click="showMenu = !showMenu">
      <UiAvatar :user="{ ...authStore.getUser }" />
      <div class="absolute bottom-0 right-0"></div>
    </div>
    <transition name="fade">
      <div
        v-if="showMenu"
        class="absolute right-0 top-12 w-max rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-1 text-black-100 shadow"
      >
        <div class="flex flex-row items-center gap-2 p-3">
          <UiIcon name="user"></UiIcon>
          {{ authStore.getUser?.email }}
        </div>
        <div
          v-for="option in options"
          :key="option.name"
          class="flex flex-row items-center gap-2 p-3 transition-colors hover:bg-black-03"
          :class="option.disabled ? 'pointer-events-none cursor-default text-black-30' : 'cursor-pointer'"
          @click="performAction(option)"
        >
          <UiIcon :name="option.icon"></UiIcon>
          {{ option.name }}
        </div>
        <div class="border-t border-solid border-black-10 p-3 text-black-60">
          <span v-if="mainStore.apiVersion">API v{{ mainStore.apiVersion }} | </span>
          Client v{{ version }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { version } from '@/package.json'

import { useMainStore } from '~/store/index'

import { useAuthStore } from '@/store/auth'
import { useUiStore } from '~/store/ui'
const authStore = useAuthStore()
const mainStore = useMainStore()
const showMenu = ref(false)
const avatar = ref()

onClickOutside(avatar, () => {
  if (showMenu.value) showMenu.value = false
})

type Option = {
  icon: string
  name: string
  path?: string
  action?: Function
  disabled?: boolean
}

const uiStore = useUiStore()

const loading = ref(false)

const options = computed<Option[]>(() => [
  {
    icon: 'log-out',
    name: 'Logout',
    disabled: loading.value,
    action: () => {
      try {
        loading.value = true

        useLogout()
      } catch (error) {
        uiStore.showSnackBanner('Failed to logout, please try again later', 'error')
      } finally {
        loading.value = false
      }
    },
  },
])

const performAction = (option: Option) => {
  if (option.path) navigateTo(option.path)
  else if (option.action) option.action()
  showMenu.value = false
}
</script>

<style scoped></style>
