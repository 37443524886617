<template>
  <div>
    <img
      v-if="user.image"
      :src="user.image"
      alt="avatar"
      class="rounded-full object-cover ring-0 transition-all"
      :class="classes"
    />
    <div
      v-else-if="initials"
      class="flex items-center justify-center rounded-full bg-primary-10 object-cover uppercase text-black-90 ring-0 transition-all"
      :class="classes"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { User } from '@/types'

type Props = {
  user: Partial<User>
  classes?: string
  size?: number
}

const props = withDefaults(defineProps<Props>(), {
  classes: 'h-10 min-w-10 w-10 text-subhead-1',
  size: 2,
})

const initials = computed(() => {
  if (!props.user.name) return ''

  return props.user.name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .substring(0, props.size)
})
</script>

<style scoped></style>
